import React, {useContext, useEffect, useState} from 'react';
import CouponBanner from "../../components/CouponBanner";
import {Redirect, useHistory} from "react-router-dom";
import {ApiServicev2, CouponServicev2, SwapService} from "../../api/Apiv2";
import withReactContent from "sweetalert2-react-content";
import Swal from 'sweetalert2'
import {InfoContext} from "../../config/InfoContext";
import styled from "styled-components"
import Confetti from 'react-confetti'

export const PointsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
`

const CouponContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    padding-bottom: 1rem;
    overflow-y: auto;
`
const MySwal = withReactContent(Swal);

export const showAlert = (message) => {
  return MySwal.fire({
    title: message,
    icon: 'error',
  })
};

const CouponList = () => {

  const [loading, setLoading] = useState(false);
  const [showConfetti, setShowConfetti] = useState(true)

  const startLoading = () => setLoading(true);
  const stopLoading = () => setLoading(false);

  const history = useHistory();

  const {sala, printer, document, card} = useContext(InfoContext);
  const [data, setData] = useState(null)

  useEffect(() => {
    getCouponData(); // Obtener los datos iniciales
  }, []);

  useEffect(() => {
    if (data) { // Ejecutar `verifyRedeemStatus` solo cuando `data` tenga un valor
      if (sala !== null && (sala.id === 999 || sala.id === 127)) {
        verifyRedeemStatus();
      }
    }
  }, [data]);

  const verifyRedeemStatus = async () => {
    const params = {
      customerId: data?.person?.id,
      productId: 772, // Producto específico para el panetón
    };

    try {
      const response = await SwapService.checkQuantityRedeems({params});
      const {quantity, stock} = response;

      if (quantity > 0 || stock <= 0) {
        setShowConfetti(false);
      } else if (sala.id === 999 || sala.id === 127) {
        const showWelcomeAlert = async () => {
          const {isConfirmed, isDismissed} = await MySwal.fire({
            title: '¡Felicidades!',
            text: 'Ganaste un panetón Gratis!',
            icon: 'success',
            showCancelButton: true,
            confirmButtonText: 'Canjear premio',
            cancelButtonText: 'Cancelar canje',
          });

          if (isConfirmed) {
            saveSwap();
          } else if (isDismissed) {
            cancelSwap();
          }
        };
        showWelcomeAlert();
        setShowConfetti(true);
      }
    } catch (error) {
      setShowConfetti(false);
      console.error(error);
    }
  };

  const cancelSwap = () => {
    MySwal.fire({
      title: 'Canje cancelado',
      text: 'Has cancelado el canje del premio.',
      icon: 'info',
    });
  };

  const saveSwap = () => {
    history.push({
      pathname: "/redeem",
      search: `?customerId=${data.person.id}&productId=772&salaId=${sala.id}`,
    });
  };

  const getCouponData = () => {
    const params = {document: document, card: card, salaId: sala.id};

    ApiServicev2.couponSummary(params).then(res => {
      setData(res.data);
    }).catch(error => {
      setData(null);
      showAlert(error.message || 'Error al obtener datos del cupón');
    });
  };

  if (sala === null) {
    return <Redirect to={'/configuration'}/>
  }


  const partialPrint = async (coupon) => {
    const {value: url} = await Swal.fire({
      title: 'Ingrese la cantidad de cupones',
      input: 'number',
      inputPlaceholder: 'Cantidad'
    });

    if (url) {
      //await Swal.fire(`Entered URL: ${url}`)
      printEvent(coupon, url);
    }
  };

  const totalPrint = async (coupon) => {
    const {value: confirm} = await Swal.fire({
      title: 'Deseas imprimir todos tus cupones ?',
      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
    });

    if (confirm) {
      const totalCupones = coupon.disponibles;
      printEvent(coupon, totalCupones);
    }
  };

  const printEvent = (coupon, quantity) => {
    startLoading();

    const params = {
      customerId: data.person.id,
      couponTypeId: coupon.couponId,
      quantity: quantity,
      salaId: sala.id,
      printerId: printer.printerId,
      computerId: printer.computerId
    };

    CouponServicev2.buildAndPrint(params).then(data => {
      history.push({
        pathname: '/printing',
        search: `?quantity=${data.quantity}&couponPrintJobId=${data.id}`
      });
    }).catch(error => {
      stopLoading();
      let errorMessage = 'Se produjo un error al procesar la solicitud';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }

      MySwal.fire({
        title: errorMessage,
        icon: 'error',
      });
    });
  };

  const partialDeposit = async (coupon) => {
    //TODO
  }

  const totalDeposit = () => {
    //TODO
  }


  return (
    data && (
      <>
        {showConfetti && sala && (sala.id === 999 || sala.id === 127) && (
          <>
            <Confetti
              tweenDuration={20000}
              initialVelocityY={40}
              initialVelocityX={2.5}
              style={{zIndex: '1061'}}
              numberOfPieces={300}
              recycle={false}
              confettiSource={{x: window.innerWidth * .25, y: window.innerHeight, w: 0, h: 0}}
              onConfettiComplete={() => setShowConfetti(false)}
            />
            <Confetti
              tweenDuration={20000}
              initialVelocityY={40}
              initialVelocityX={2.5}
              style={{zIndex: '1061'}}
              numberOfPieces={300}
              recycle={false}
              confettiSource={{x: window.innerWidth * .75, y: window.innerHeight, w: 0, h: 0}}
              onConfettiComplete={() => setShowConfetti(false)}
            />
          </>
        )}
        <CouponContainer className="scrollbar">
          {data.couponList
            .filter(coupon => coupon.moduleShow)
            .map((coupon) =>
              <div key={coupon.couponId} style={{display: "flex", padding: "0 0.5rem"}}>
                <CouponBanner
                  coupon={coupon}
                  totalPrint={() => totalPrint(coupon)}
                  partialPrint={() => partialPrint(coupon)}
                  totalDeposit={() => totalDeposit(coupon)}
                  partialDeposit={() => partialDeposit(coupon)}
                  loading={loading}
                />
              </div>
            )}
        </CouponContainer>
      </>
    )
  );

};

export default CouponList;
